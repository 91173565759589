<header class="header" *ngIf="loggedIn">
  <div class="header__inner row--between">

    <div class="header__col1">
      <a class="header__link" routerLink="/">
        <img class="header__logo desktop" src="assets/images/logo-light.svg" alt="QuoteTrack">
        <img class="header__logo mobile" src="assets/images/logo-mobile.svg" alt="QuoteTrack">
      </a>
    </div>

    <div class="header__col2">
      <div class="row">
        <a class="user__avatar" title="View User" *ngIf="currentUser" [ngClass]="currentUser.role" [routerLink]="['/users', 'view', currentUser.id]" [queryParams]="{self: true}">
          <span>{{getUserInitials(currentUser.first_name, currentUser.last_name)}}</span>
        </a>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon class="menu-open" fontIcon="menu"></mat-icon>
          <mat-icon class="menu-close" fontIcon="close"></mat-icon>
        </button>
      </div>

      <mat-menu #menu="matMenu" class="main-menu menu-panel" backdropClass="menu-backdrop">
        <button mat-menu-item routerLink="search" *ngIf="currentUser && currentUser.role !== 'principal'">
          <span>Search</span>
        </button>

        <ng-container *ngIf="currentUser && currentUser.role !== 'principal'">
          <button mat-menu-item routerLink="lead/create">
            <span>New Lead</span>
          </button>
          <button mat-menu-item routerLink="request/create">
            <span>New Request</span>
          </button>
          <button mat-menu-item routerLink="/reports">
            <span>Reports</span>
          </button>
          <button mat-menu-item routerLink="/customers">
            <span>Contacts</span>
          </button>
        </ng-container>

        <button mat-menu-item routerLink="/users" *ngIf="currentUser && currentUser.role === 'admin'">
          <span>Users</span>
        </button>

        <br>
        <button mat-menu-item (click)="logout();">
          <span>Log Out</span>
        </button>

        <em>v1.10.0</em>

      </mat-menu>

    </div>

  </div>
</header>

<main id="main" class="main" [ngClass]="{'full-screen': !loggedIn}">
  <div class="container--fluid">
    <router-outlet></router-outlet>
  </div>

  <div class="project__loading loading --overlay" *ngIf="loggingOut">
    <div class="loading__bar">Logging Out<span>.</span><span>.</span><span>.</span></div>
  </div>
</main>
